import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import useDeadpollList from '@a2d24/care-at-work-core/Deadpoll/useDeadpollList';
import { useStores } from '@a2d24/care-at-work-state/stores';
import './styles.css'

const localizer = momentLocalizer(moment)
const getCalendarEvent = (bookingObj) =>{
    return {
        id: bookingObj.booking_id,
        title: bookingObj.tariff_description??"Consult",
        start: new Date(moment(bookingObj.scheduled_at)),
        end: new Date(bookingObj?.meta_data?.booking_payload?.end_datetime?moment(moment.bookingObj?.meta_data?.booking_payload?.end_datetime):moment(bookingObj.scheduled_at).add(1, "hour"))
    }
}
const AppointmentsCalendar = ({ props}) => {
    const { authStore } = useStores();
    const deadpollListId = props?.deadpoll_list_id;
    const {data:deadpollBookings, loading:isLoadingBookings} = useDeadpollList(`${authStore.occhealthPracticeId}#${deadpollListId}`);
    return(
        <div style={{width: '100%'}}>
            <Calendar
                localizer={localizer}
                events={
                    props.bookings?props.bookings.map((booking)=>getCalendarEvent(booking))
                        :
                        (isLoadingBookings?[]: deadpollBookings.map((booking)=>getCalendarEvent(booking)))
                }
                startAccessor="start"
                endAccessor="end"
                style={{
                    height: 500,
                }}
                onSelectEvent={({...event})=>{
                    if(props.onSelectEvent) {
                        props.onSelectEvent(event)
                    }
                }}
                views={['month', 'week', 'day']}
            />
        </div>
    )
}

export default AppointmentsCalendar
